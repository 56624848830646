import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Me } from '@models/me/me';
import { MeSerializer } from '@models/me/me.serializer';
import { SRS } from '@models/srs/srs';
import { User } from '@models/user/user';
import { UserSerializer } from '@models/user/user.serializer';
import { WorkspaceUser } from '@models/workspace-user/workspace-user';
import { WorkspaceUserSerializer } from '@models/workspace-user/workspace-user.serializer';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class UserService extends EntityService<User> {
  private _meSerializer = new MeSerializer();
  private _userSerializer = new UserSerializer();

  private _workspaceUserSerializer = new WorkspaceUserSerializer();

  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(_http, environment.apiURL, 'users/', new UserSerializer());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Retrieve logged in user
   */
  getMe(): Observable<CptSuccessResponse<Me>> {
    return this._http
      .get<CptSuccessResponse<Me>>(`${this.url}/me`, this.options)
      .pipe(
        map((response: CptSuccessResponse<Me>) => {
          response.content = this._meSerializer.toEntity(
            response.content
          ) as Me;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Retrieve logged in user
   */
  getUser(userUid: string): Observable<CptSuccessResponse<User>> {
    return this._http
      .get<CptSuccessResponse<User>>(
        `${environment.apiExtUrl}/pub/users/${userUid}`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<User>) => {
          response.content = this._userSerializer.toEntity(
            response.content
          ) as User;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Update current workspace of the logged in user
   */
  updateMeCurrentWorkspace(
    newWorkspaceUid: string
  ): Observable<CptSuccessResponse<null>> {
    return this._http
      .patch<CptSuccessResponse<null>>(
        `${this.url}/me/current_workspace`,
        {
          new_workspace_uid: newWorkspaceUid
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Retrieve information about the logged in user in the current workspace
   */
  readWorkspaceUserInfo(): Observable<CptSuccessResponse<WorkspaceUser>> {
    return this._http
      .get<CptSuccessResponse<WorkspaceUser>>(
        `${this.url}/me/workspace_user_info`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<WorkspaceUser>) => {
          response.content = this._workspaceUserSerializer.toEntity(
            response.content
          ) as WorkspaceUser;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   *  Update User's notifications preferences
   */
  updateNotifications(
    notificationKey: keyof WorkspaceUser,
    value: boolean
  ): Observable<CptSuccessResponse<any>> {
    const snakeNotificationKey =
      this._workspaceUserSerializer.mapping.get(notificationKey) ||
      notificationKey;

    return this._http
      .patch<CptSuccessResponse<any>>(
        `${this.url}/me/notification_preferences`,
        {
          [snakeNotificationKey]: value
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   *  Update User's slack webhook url
   */
  updateSlackWebhookUrl(
    slackWebhookUrl: string
  ): Observable<CptSuccessResponse<any>> {
    return this._http
      .patch<CptSuccessResponse<any>>(
        `${this.url}/me/slack_webhook_url`,
        {
          new_slack_webhook_url: slackWebhookUrl
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Override to user Patch method to update user
   */
  override update(user: User): Observable<CptSuccessResponse<User>> {
    return this.httpClient
      .patch<CptSuccessResponse<User>>(
        `${this.url}/${this.resource}${user.uid}`,
        this.serializer.toJSON(user),
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<User>) => {
          response.content = this.serializer.toEntity(response.content) as User;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Returns users that don't have an account configured for the given integration
   */
  listUsersWithoutAccountForIntegration(
    integrationUid: string,
    srs: SRS<User> | null
  ): Observable<CptSuccessResponse<User[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this._http
      .get<CptSuccessResponse<User[]>>(
        `${this.url}/users/integrations/${integrationUid}/`,
        {
          withCredentials: true,
          params
        }
      )
      .pipe(
        map((response: CptSuccessResponse<User[]>) => {
          response.content = this.toEntities(response.content) as User[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Retrieves the number of ongoing runs for a user
   */
  readUserOngoingRuns(
    userUid: string
  ): Observable<CptSuccessResponse<{ user_ongoing_runs_count: number }>> {
    return this._http
      .get<CptSuccessResponse<{ user_ongoing_runs_count: number }>>(
        `${this.url}/${this.resource}${userUid}/ongoing_runs`,
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Override to user Patch method to update user
   */
  invite(
    user: User,
    workspaceUid: string
  ): Observable<CptSuccessResponse<User>> {
    let params: HttpParams = new HttpParams({
      fromObject: {
        target_workspace_uid: workspaceUid
      }
    });
    return this.httpClient
      .post<CptSuccessResponse<User>>(
        `${this.url}/${this.resource}${user.uid}/invite`,
        this.serializer.toJSON(user),
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<User>) => {
          response.content = this.serializer.toEntity(response.content) as User;
          return response;
        }),
        catchError(this.handleError)
      );
  }
}
